import * as React from "react";
import Typography from "@mui/material/Typography";
import { List, ListItem } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AppPage from "../Components/AppPage";

export default function ServoSteps() {
  const auto_rows = [
    { mode: "Continuous Mode (C)", behave: "Sweeping direction and speed" },
    { mode: "Servo Mode (S)", behave: "Sweeping between limits" },
  ];

  const manual_rows = [
    {
      mode: "Continuous Mode (C)",
      left: "Counterclockwise Rotation",
      prog: "Stopped",
      right: "Clockwise Rotation",
    },
    {
      mode: "Servo Mode (S)",
      left: "Move to left limt",
      prog: "Move to center position",
      right: "Move to right limit",
    },
  ];

  return (
    <AppPage title="Servo Programming">
      <Typography component="h5" variant="h5" sx={{ px: 0 }} color="inherit">
        Switching Modes
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        Follow the steps below to switch a REV Smart Robot Servo between
        Continuous Mode and Servo Mode. The figure below shows the process to
        select Continuous Mode.
      </Typography>
      <center>
        <img
          src="servo_switch.png"
          style={{
            maxHeight: "400px",
            maxWidth: "100%",
            padding: 0,
            margin: 0,
          }}
        ></img>
      </center>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        1. Connect the SRS to the programmer.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        2. Turn on the programmer. Slide the mode switch to the desired mode: C
        - Continuous, S - Servo.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        3. Slide the mode switch to the desired mode: C - Continuous, S - Servo.
        Press and release the PROGRAM button once.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        4. Press and release the PROGRAM button once.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        5. The PROGRAM LED should blink and then stay solid indicating success.
      </Typography>

      <Typography
        component="h5"
        variant="h5"
        sx={{ px: 0, pt: 3 }}
        color="inherit"
      >
        Setting Angular Limits
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        Follow the steps below to set the angular limits for the Servo Mode. The
        figure below shows an example of setting a left and right limits at -30°
        and +60° respectively.
      </Typography>
      <center>
        <img
          src="servo_range.png"
          style={{
            maxHeight: "400px",
            maxWidth: "100%",
            padding: 0,
            margin: 0,
          }}
        ></img>
      </center>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        1. Connect the SRS to the programmer.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        2. Turn on the programmer.{" "}
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        3. Slide the mode switch to S position.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        4. This step is optional, but recommended to make it easier to see the
        valid limit ranges. Please refer to the SRS User's Manual for more
        information about the valid limit ranges.
      </Typography>
      <Typography component="p" sx={{ pl: 3, pe: 0 }} color="inherit">
        a. Press and release the TEST button twice to enter Manual Test Mode
        (see Test Modes for more information)
      </Typography>
      <Typography component="p" sx={{ pl: 3, pe: 0 }} color="inherit">
        b. Press the PROGRAM button to center the servo at 0°.
      </Typography>
      <Typography component="p" sx={{ pl: 3, pe: 0 }} color="inherit">
        c. Press and release the TEST button once to leave the test mode.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        5. Manually rotate the servo to the desired left limit position.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        6. Press and release the LEFT button. The LEFT LED will illuminate if
        the position is valid.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        7. Manually rotate the servo to the desired right limit position.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        8. Press and release the RIGHT button. The RIGHT LED will illuminate if
        the position is valid.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        9. After both limits are set, press and release the PROGRAM button. The
        PROGRAM LED should blink and then stay solid indicating success.
      </Typography>

      <Typography
        component="h5"
        variant="h5"
        sx={{ px: 0, pt: 3 }}
        color="inherit"
      >
        Resetting to Default
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        Follow the steps below to reset the Smart Robot Servo to its default
        mode and limits. The figure below shows the process to reset to
        defaults.
      </Typography>
      <center>
        <img
          style={{
            maxHeight: "400px",
            maxWidth: "100%",
            padding: 0,
            margin: 0,
          }}
          src="servo_default.png"
        ></img>
      </center>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        1. Connect the SRS to the programmer.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        2. Turn on the programmer.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        3. Slide the mode switch to S position.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        4. Press and hold the PROGRAM button for at least 5 seconds.
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        5. The LEDs will blink and then the PROGRAM LED will stay solid
        indicating success.
      </Typography>

      <Typography
        component="h5"
        variant="h5"
        sx={{ px: 0, pt: 3 }}
        color="inherit"
      >
        Test Modes
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        In either Continuous or Servo Modes, pressing and releasing the TEST
        button cycles through the two test modes:
      </Typography>
      <List
        sx={{
          listStyleType: "disc",
          pl: 2,
          ml: 5,
          "& .MuiListItem-root": {
            display: "list-item",
          },
        }}
      >
        <ListItem sx={{ py: 0, my: 0 }}>
          1st press - Automatic Sweep Mode
        </ListItem>
        <ListItem sx={{ py: 0, my: 0 }}>2nd press - Manual Test Mode</ListItem>
        <ListItem sx={{ py: 0, my: 0 }}>
          3rd press - Return to default state
        </ListItem>
      </List>

      <Typography
        component="h6"
        variant="h6"
        sx={{ px: 0, pt: 3 }}
        color="inherit"
      >
        Automatic Sweep Mode
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        In Automatic Sweep Mode, the SRS Programmer will automatically sweep the
        SRS through motions appropriate for its configuration. the table below
        describes the behavior based on the configured mode.
      </Typography>
      <Table sx={{ maxWidth: 550, alignSelf: "center" }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Servo and Programmer Mode</TableCell>
            <TableCell>Behavior</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {auto_rows.map((row) => (
            <TableRow
              key={row.mode}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.mode}
              </TableCell>
              <TableCell>{row.behave}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography
        component="h6"
        variant="h6"
        sx={{ px: 0, pt: 3 }}
        color="inherit"
      >
        Manual Test Mode
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        In Manual Test Mode the LEFT, PROGRAM, and RIGHT buttons control the
        movement of the SRS. The table below describes how the SRS will behave
        based on the configured mode.
      </Typography>
      <Table sx={{ maxWidth: 850, alignSelf: "center" }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Servo and Programmer Mode</TableCell>
            <TableCell>LEFT Button</TableCell>
            <TableCell>PROGRAM Button</TableCell>
            <TableCell>RIGHT Button</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {manual_rows.map((row) => (
            <TableRow
              key={row.mode}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.mode}
              </TableCell>
              <TableCell>{row.left}</TableCell>
              <TableCell>{row.prog}</TableCell>
              <TableCell>{row.right}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </AppPage>
  );
}
