import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Button, useMediaQuery } from "@mui/material";
import moment from "moment";
import { Log, Tables } from "../Components/database.types";
import BatteryCard from "../Components/BatteryCard";
import AppPage from "../Components/AppPage";
import { HelpTableProps } from "./CurrentTable";
import { useSelector } from "@legendapp/state/react";

const tmp: Tables<"logs"> = {
  assigned: "",
  country: "",
  helpType: "",
  id: 0,
  isComplete: false,
  isNew: false,
  location: "",
  notes: "",
  startTime: 0,
  stopTime: 0,
};

export default function BatteryTable(props: HelpTableProps) {
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState<Tables<"logs">>(tmp);

  const isBig = useMediaQuery("(min-width:1200px)");

  const handleClickOpen = (row: Tables<"logs">) => {
    setRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns: GridColDef[] = [
    { field: "country", headerName: "Country", flex: 1.75 },
    { field: "helpType", headerName: "Type", flex: 0.75 },
    { field: "notes", headerName: "Type", flex: 1 },
    {
      field: "startTime",
      headerName: "Start Time",
      flex: 1.5,
      valueFormatter: (params) => moment(params?.value).format("hh:mmA DD-MMM"),
    },
    {
      field: "isComplete",
      headerName: "Complete?",
      flex: 1,
      renderCell: (params: GridRenderCellParams<Boolean>) => (
        <div>{params.value ? "Yes" : "No"}</div>
      ),
    },
    {
      field: "info",
      headerName: "Info",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            tabIndex={params.hasFocus ? 0 : -1}
            onClick={() => handleClickOpen(params.row)}
          >
            Open
          </Button>
        </strong>
      ),
    },
  ];

  return (
    <AppPage title="Battery Charging">
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={props.tableData}
          columns={columns}
          autoHeight
          disableRowSelectionOnClick
          initialState={{
            columns: {
              columnVisibilityModel: {
                stopTime: false,
                assigned: false,
                helpType: false,
                isNew: false,
                startTime: isBig,
                location: false,
                notes: true,
                isComplete: isBig,
                info: true,
              },
            },
            sorting: {
              sortModel: [{ field: "startTime", sort: "asc" }],
            },
            filter: {
              filterModel: {
                items: [
                  {
                    field: "isComplete",
                    operator: "contains",
                    value: "false",
                  },
                ],
              },
            },
          }}
        />
      </div>
      <BatteryCard row={row} open={open} onClose={handleClose} />
    </AppPage>
  );
}
