import * as React from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Paper,
  Stack,
} from "@mui/material";
import moment from "moment";
import { Tables } from "../Components/database.types";
import { supabase } from "../Components/DbConnection";

export interface InfoCardProps {
  open: boolean;
  row: Tables<"logs">;
  onClose: Function;
}

async function updateRow(
  idVal: number,
  newAssigned: string,
  newComplete: boolean,
  newNotes: string
) {
  const stopTime = newComplete ? Date.now() : 0;
  const { data, error } = await supabase
    .from("logs")
    .update({
      assigned: newAssigned,
      isComplete: newComplete,
      notes: newNotes,
      stopTime: stopTime,
    })
    .eq("id", idVal)
    .select();
}

export default function InfoCard(props: InfoCardProps) {
  const [newAssigned, setNewAssigned] = React.useState(props.row.assigned);
  const [newNotes, setNewNotes] = React.useState(props.row.notes);
  const [newComplete, setNewComplete] = React.useState(props.row.isComplete);

  React.useEffect(() => {
    setNewAssigned(props.row.assigned);
    setNewNotes(props.row.notes);
    setNewComplete(props.row.isComplete);
  }, [props.row.assigned, props.row.notes, props.row.isComplete]);

  const handleClose = () => {
    props.onClose();
  };

  const updateRowAndClose = () => {
    props.onClose();
    updateRow(props.row.id, newAssigned, newComplete, newNotes);
  };

  const updateIsComplete = () => {
    setNewComplete(!newComplete);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      sx={{ width: "100%", p: 0, m: 0 }}
    >
      <Paper
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minWidth: "400px",
          m: 0,
        }}
      >
        <Stack spacing={1}>
          <Stack direction="row">
            <Typography
              component="h3"
              variant="h6"
              sx={{ pt: 1, pr: 2 }}
              color="inherit"
            >
              Country:
            </Typography>
            <Typography component="p" sx={{ pt: 1.625 }}>
              {props.row.country}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography
              component="h3"
              variant="h6"
              sx={{ pt: 1, pr: 2 }}
              color="inherit"
            >
              Type:
            </Typography>
            <Typography component="p" sx={{ pt: 1.625 }}>
              {newNotes}
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography
              component="h3"
              variant="h6"
              sx={{ pt: 1, pr: 2 }}
              color="inherit"
            >
              Start Time:
            </Typography>
            <Typography component="p" sx={{ pt: 1.625 }}>
              {moment(props.row.startTime).format("hh:mmA M/DD")}
            </Typography>
          </Stack>
          {props.row.stopTime > 0 && (
            <Stack direction="row">
              <Typography
                component="h3"
                variant="h6"
                sx={{ pt: 1, pr: 2 }}
                color="inherit"
              >
                Stop Time:
              </Typography>
              <Typography component="p" sx={{ pt: 1.625 }}>
                {moment(props.row.stopTime).format("hh:mmA M/DD")}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" spacing={3}>
            <FormControlLabel
              value="isComplete"
              control={
                <Checkbox
                  color="primary"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 36 } }}
                  checked={newComplete}
                  value={newComplete}
                  onChange={updateIsComplete}
                />
              }
              label={
                <Typography
                  component="h3"
                  variant="h6"
                  sx={{ pt: 0 }}
                  color="inherit"
                >
                  Completed:
                </Typography>
              }
              labelPlacement="start"
            />
          </Stack>

          <Button variant="contained" size="small" onClick={updateRowAndClose}>
            Save
          </Button>
        </Stack>
      </Paper>
    </Dialog>
  );
}
