import * as React from "react";
import Typography from "@mui/material/Typography";
import { Card, CardContent, List, ListItem } from "@mui/material";
import moment from "moment";
import Masonry from "@mui/lab/Masonry";
import { Log, Tables } from "../Components/database.types";
import AppPage from "../Components/AppPage";
import { HelpTableProps } from "./CurrentTable";
import { useSelector } from "@legendapp/state/react";

const tmp: Tables<"logs"> = {
  assigned: "",
  country: "",
  helpType: "",
  id: 0,
  isComplete: false,
  isNew: false,
  location: "",
  notes: "",
  startTime: 0,
  stopTime: 0,
};

export default function Stats(props: HelpTableProps) {
  const dataValues = [
    {
      title: "Countries Helped",
      body: new Set(props.tableData.map((item) => item.country)).size,
    },
    {
      title: "Time Spent",
      body: (
        <>
          {moment
            .duration(
              props.tableData.reduce(
                (accum, item) =>
                  accum +
                  (item.stopTime > 0 ? item.stopTime : item.startTime) -
                  item.startTime,
                0
              ),
              "milliseconds"
            )
            .asHours()
            .toFixed(0)}{" "}
          hours{" "}
        </>
      ),
    },
    {
      title: "Issues",
      body: (
        <List>
          <ListItem sx={{ p: 0, m: 0 }}>
            Open:{" "}
            {
              props.tableData.filter((entry) => {
                return !entry.isComplete;
              }).length
            }
          </ListItem>
          <ListItem sx={{ p: 0, m: 0 }}>
            Closed:{" "}
            {
              props.tableData.filter((entry) => {
                return entry.isComplete;
              }).length
            }
          </ListItem>
          <ListItem sx={{ p: 0, m: 0 }}>
            Total: {props.tableData.length}
          </ListItem>
        </List>
      ),
    },
    {
      title: "Issue Types",
      body: (
        <List>
          <ListItem sx={{ p: 0, m: 0 }}>
            Hardware:{" "}
            {
              props.tableData.filter((item) => {
                return item.helpType === "Hardware";
              }).length
            }
          </ListItem>
          <ListItem sx={{ p: 0, m: 0 }}>
            Software:{" "}
            {
              props.tableData.filter((item) => {
                return item.helpType === "Software";
              }).length
            }
          </ListItem>
          <ListItem sx={{ p: 0, m: 0 }}>
            Machine Shop:{" "}
            {
              props.tableData.filter((item) => {
                return item.helpType === "Machine Shop";
              }).length
            }
          </ListItem>
          <ListItem sx={{ p: 0, m: 0 }}>
            Battery Charging:{" "}
            {
              props.tableData.filter((item) => {
                return item.helpType === "Charging";
              }).length
            }
          </ListItem>
        </List>
      ),
    },
    {
      title: "Locations",
      body: (
        <List>
          <ListItem sx={{ p: 0, m: 0 }}>
            Hospital:{" "}
            {
              props.tableData.filter((item) => {
                return item.location === "Hospital";
              }).length
            }
          </ListItem>
          <ListItem sx={{ p: 0, m: 0 }}>
            Machine Shop:{" "}
            {
              props.tableData.filter((item) => {
                return item.location === "Machine Shop";
              }).length
            }
          </ListItem>
          <ListItem sx={{ p: 0, m: 0 }}>
            Team Pit:{" "}
            {
              props.tableData.filter((item) => {
                return item.location === "Team Pit";
              }).length
            }
          </ListItem>
        </List>
      ),
    },
  ];
  return (
    <AppPage title="Flying Squad Stats">
      <Masonry
        columns={{ xs: 1, sm: 3, lg: 4, xl: 5 }}
        spacing={0}
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
      >
        {dataValues.map((item, index) => (
          <Card
            sx={{
              minWidth: "200px",
              maxWidth: "400px",
              m: 0,
              border: "none",
              boxShadow: "none",
              p: 0,
            }}
            key={index}
          >
            <CardContent>
              <Typography
                variant="h4"
                component="div"
                align="left"
                sx={{ p: 0, m: 0 }}
              >
                {item.title}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                color="text.secondary"
                alignContent="left"
                align="left"
              >
                {item.body}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Masonry>
    </AppPage>
  );
}
