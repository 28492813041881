import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import type {} from "@mui/x-data-grid/themeAugmentation";

import { inject } from "@vercel/analytics";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import HelpForm from "./HelpForm";
import CurrentTable from "./CurrentTable";
import LookupPage from "./LookupPage";
import ServoSteps from "./ServoSteps";
import ServoGears from "./ServoGears";
import Stats from "./Stats";

import BatteryTable from "./BatteryTable";
import InspectionList from "./InspectionList";
import TopBar from "../Components/TopBar";
import { supabase } from "../Components/DbConnection";

import { Tables } from "../Components/database.types";
import MatchViewer from "./MatchViewer";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#1c1f5b",
    },
  },

  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
        },
      },
    },
  },
});

async function fetchData() {
  const { data, error } = await supabase.from("logs").select();

  return data;
}

export default function App() {
  inject();

  const [open, setOpen] = React.useState(false);
  const [allData, setAllData] = React.useState<Tables<"logs">[]>([]);
  const [battData, setBattData] = React.useState<Tables<"logs">[]>([]);
  const [helpData, setHelpData] = React.useState<Tables<"logs">[]>([]);
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetchData().then((data) => {
      if (data !== null) {
        setAllData(data!);
        updateDataSets(data);
        setLoading(false);
      }
    });
  }, []);

  function updateDataSets(data: Tables<"logs">[]) {
    setBattData(data.filter((entry) => entry.helpType === "Charging"));
    setHelpData(data.filter((entry) => entry.helpType != "Charging"));
  }

  const logs = supabase
    .channel("custom-all-channel")
    .on(
      "postgres_changes",
      { event: "*", schema: "public", table: "logs" },
      (payload) => {
        const idx: number = allData.findIndex(
          (x) => x.id == (payload.new as Tables<"logs">).id
        );

        let newList: Tables<"logs">[] = allData.slice();
        if (idx === -1) {
          newList.push(payload.new as Tables<"logs">);
        } else {
          newList[idx] = payload.new as Tables<"logs">;
        }
        setAllData(newList);
        updateDataSets(newList);
      }
    )
    .subscribe();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <Box
          sx={{ display: "flex" }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <CssBaseline />
          <TopBar toggleDrawer={toggleDrawer} open={open} />
          <Box
            component="main"
            onClick={toggleDrawer(false)}
            sx={{
              m: 0,
              p: 0,
              backgroundColor: (theme) => theme.palette.grey[100],
              flexGrow: 1,
              height: "100vh",
            }}
          >
            <Toolbar />
            <Routes>
              <Route path="/" element={<HelpForm />} />
              <Route
                path="/current"
                element={
                  <CurrentTable tableData={helpData} isLoading={isLoading} />
                }
              />
              <Route
                path="/charging"
                element={
                  <BatteryTable tableData={battData} isLoading={isLoading} />
                }
              />
              <Route path="/inspect" element={<InspectionList />} />
              <Route
                path="/lookup"
                element={
                  <LookupPage tableData={allData} isLoading={isLoading} />
                }
              />
              <Route path="/servo" element={<ServoSteps />} />
              <Route path="/gears" element={<ServoGears />} />
              <Route
                path="/stats"
                element={<Stats tableData={allData} isLoading={isLoading} />}
              />
              <Route path="/schedule" element={<MatchViewer />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Box>
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}
