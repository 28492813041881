import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Button, useMediaQuery } from "@mui/material";
import moment from "moment";

import { Tables } from "../Components/database.types";
import InfoCard from "../Components/InfoCard";
import AppPage from "../Components/AppPage";

const tmp: Tables<"logs"> = {
  assigned: "",
  country: "",
  helpType: "",
  id: 0,
  isComplete: false,
  isNew: false,
  location: "",
  notes: "",
  startTime: 0,
  stopTime: 0,
};

export interface HelpTableProps {
  tableData: Tables<"logs">[];
  isLoading: boolean;
}

export default function CurrentTable(props: HelpTableProps) {
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState<Tables<"logs">>(tmp);

  const notSmall = useMediaQuery("(min-width:800px)");
  const isBig = useMediaQuery("(min-width:1200px)");

  const handleClickOpen = (row: Tables<"logs">) => {
    setRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns: GridColDef[] = [
    { field: "country", headerName: "Country", flex: 1.75 },
    { field: "helpType", headerName: "Type", flex: 1 },
    {
      field: "isNew",
      headerName: "New?",
      flex: 0.75,
      renderCell: (params: GridRenderCellParams<Boolean>) => (
        <div>{params.value ? "Yes" : "No"}</div>
      ),
    },
    { field: "location", headerName: "Location", flex: 1 },
    {
      field: "assigned",
      headerName: "Assigned",
      flex: 0.875,
    },
    { field: "notes", headerName: "Notes", flex: 3 },
    {
      field: "startTime",
      headerName: "Start Time",
      flex: 1.5,
      valueFormatter: (params) => moment(params?.value).format("hh:mmA DD-MMM"),
    },
    {
      field: "stopTime",
      headerName: "Stop Time",
      flex: 1.5,
      valueFormatter: (params) =>
        moment(params?.value).format("hh:mmA DD-MMMM"),
    },
    {
      field: "isComplete",
      headerName: "Complete?",
      flex: 1.25,
      renderCell: (params: GridRenderCellParams<Boolean>) => (
        <div>{params.value ? "Yes" : "No"}</div>
      ),
    },
    {
      field: "info",
      headerName: "Info",
      flex: 0.75,
      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            tabIndex={params.hasFocus ? 0 : -1}
            onClick={() => handleClickOpen(params.row)}
          >
            Open
          </Button>
        </strong>
      ),
    },
  ];

  return (
    <AppPage title="Help Requests">
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={props.tableData}
          columns={columns}
          autoHeight
          disableRowSelectionOnClick
          initialState={{
            columns: {
              columnVisibilityModel: {
                stopTime: false,
                helpType: notSmall,
                isNew: isBig,
                startTime: isBig,
                location: isBig,
                notes: isBig,
                isComplete: isBig,
                info: true,
              },
            },
            sorting: {
              sortModel: [{ field: "startTime", sort: "asc" }],
            },
            filter: {
              filterModel: {
                items: [
                  {
                    field: "isComplete",
                    operator: "contains",
                    value: "false",
                  },
                ],
              },
            },
          }}
        />
      </div>
      <InfoCard row={row} open={open} onClose={handleClose} />
    </AppPage>
  );
}
