const Countries = [
  { value: "Afghanistan", label: "Afghanistan", code: "AFG", short: "af" },
  { value: "Albania", label: "Albania", code: "ALB", short: "al" },
  { value: "Algeria", label: "Algeria", code: "ALG", short: "dz" },
  { value: "Angola", label: "Angola", code: "ANG", short: "ao" },
  { value: "Argentina", label: "Argentina", code: "ARG", short: "ar" },
  { value: "Armenia", label: "Armenia", code: "ARM", short: "am" },
  { value: "Aruba", label: "Aruba", code: "ARU", short: "aw" },
  {
    value: "Antigua and Barbuda",
    label: "Antigua and Barbuda",
    code: "ATG",
    short: "ag",
  },
  { value: "Australia", label: "Australia", code: "AUS", short: "au" },
  { value: "Austria", label: "Austria", code: "AUT", short: "at" },
  { value: "Azerbaijan", label: "Azerbaijan", code: "AZE", short: "az" },
  { value: "Bahamas", label: "Bahamas", code: "BAH", short: "bs" },
  { value: "Bangladesh", label: "Bangladesh", code: "BAN", short: "bd" },
  { value: "Barbados", label: "Barbados", code: "BAR", short: "bb" },
  { value: "Burundi", label: "Burundi", code: "BDI", short: "bi" },
  { value: "Bermuda", label: "Bermuda", code: "BER", short: "bm" },
  {
    value: "Bosnia and Herzegovina",
    label: "Bosnia and Herzegovina",
    code: "BIH",
    short: "ba",
  },
  { value: "Belize", label: "Belize", code: "BIZ", short: "bz" },
  { value: "Belarus", label: "Belarus", code: "BLR", short: "by" },
  { value: "Bolivia", label: "Bolivia", code: "BOL", short: "bo" },
  { value: "Botswana", label: "Botswana", code: "BOT", short: "bw" },
  { value: "Brazil", label: "Brazil", code: "BRA", short: "br" },
  {
    value: "Brunei Darussalam",
    label: "Brunei Darussalam",
    code: "BRU",
    short: "bn",
  },
  { value: "Bulgaria", label: "Bulgaria", code: "BUL", short: "bg" },
  { value: "Burkina Faso", label: "Burkina Faso", code: "BUR", short: "bf" },
  { value: "Cambodia", label: "Cambodia", code: "CAM", short: "kh" },
  { value: "Canada", label: "Canada", code: "CAN", short: "ca" },
  {
    value: "Cayman Islands",
    label: "Cayman Islands",
    code: "CAY",
    short: "ky",
  },
  { value: "Chad", label: "Chad", code: "CHA", short: "td" },
  { value: "Chile", label: "Chile", code: "CHI", short: "cl" },
  {
    value: "China, People's Republic of",
    label: "China, People's Republic of",
    code: "CHN",
    short: "cn",
  },
  { value: "Côte d'Ivoire", label: "Côte d'Ivoire", code: "CIV", short: "ci" },
  { value: "Cameroon", label: "Cameroon", code: "CMR", short: "cm" },
  {
    value: "Congo, Democratic Republic of the",
    label: "Congo, Democratic Republic of the",
    code: "COD",
    short: "cd",
  },
  { value: "Cook Islands", label: "Cook Islands", code: "COK", short: "ck" },
  { value: "Colombia", label: "Colombia", code: "COL", short: "co" },
  { value: "Cabo Verde", label: "Cabo Verde", code: "CPV", short: "cv" },
  { value: "Costa Rica", label: "Costa Rica", code: "CRC", short: "cr" },
  { value: "Croatia", label: "Croatia", code: "CRO", short: "hr" },
  { value: "Cuba", label: "Cuba", code: "CUB", short: "cu" },
  { value: "Cyprus", label: "Cyprus", code: "CYP", short: "cy" },
  {
    value: "Czech Republic",
    label: "Czech Republic",
    code: "CZE",
    short: "cz",
  },
  { value: "Denmark", label: "Denmark", code: "DEN", short: "dk" },
  { value: "Djibouti", label: "Djibouti", code: "DJI", short: "dj" },
  { value: "Dominica", label: "Dominica", code: "DMA", short: "dm" },
  {
    value: "Dominican Republic",
    label: "Dominican Republic",
    code: "DOM",
    short: "do",
  },
  { value: "Ecuador", label: "Ecuador", code: "ECU", short: "ec" },
  { value: "Egypt", label: "Egypt", code: "EGY", short: "eg" },
  { value: "Eritrea", label: "Eritrea", code: "ERI", short: "er" },
  { value: "El Salvador", label: "El Salvador", code: "ESA", short: "sv" },
  { value: "Spain", label: "Spain", code: "ESP", short: "es" },
  { value: "Estonia", label: "Estonia", code: "EST", short: "ee" },
  { value: "Ethiopia", label: "Ethiopia", code: "ETH", short: "et" },
  { value: "Finland", label: "Finland", code: "FIN", short: "fi" },
  { value: "France", label: "France", code: "FRA", short: "fr" },
  {
    value: "Micronesia, Federated States of",
    label: "Micronesia, Federated States of",
    code: "FSM",
    short: "fm",
  },
  { value: "Gabon", label: "Gabon", code: "GAB", short: "ga" },
  { value: "Gambia", label: "Gambia", code: "GAM", short: "gm" },
  {
    value: "United Kingdom",
    label: "United Kingdom",
    code: "GBR",
    short: "gb",
  },
  { value: "Guinea-Bissau", label: "Guinea-Bissau", code: "GBS", short: "gw" },
  { value: "Georgia", label: "Georgia", code: "GEO", short: "ge" },
  {
    value: "Equatorial Guinea",
    label: "Equatorial Guinea",
    code: "GEQ",
    short: "gq",
  },
  { value: "Ghana", label: "Ghana", code: "GHA", short: "gh" },
  { value: "Greece", label: "Greece", code: "GRE", short: "gr" },
  { value: "Grenada", label: "Grenada", code: "GRN", short: "gd" },
  { value: "Guatemala", label: "Guatemala", code: "GUA", short: "gt" },
  { value: "Guinea", label: "Guinea", code: "GUI", short: "gn" },
  { value: "Guam", label: "Guam", code: "GUM", short: "gu" },
  { value: "Guyana", label: "Guyana", code: "GUY", short: "gy" },
  { value: "Haiti", label: "Haiti", code: "HAI", short: "ht" },
  {
    value: "Hong Kong, China",
    label: "Hong Kong, China",
    code: "HKG",
    short: "hk",
  },
  { value: "Honduras", label: "Honduras", code: "HON", short: "hn" },
  { value: "Hungary", label: "Hungary", code: "HUN", short: "hu" },
  { value: "Indonesia", label: "Indonesia", code: "INA", short: "id" },
  { value: "India", label: "India", code: "IND", short: "in" },
  { value: "Iran", label: "Iran", code: "IRI", short: "ir" },
  { value: "Ireland", label: "Ireland", code: "IRL", short: "ie" },
  { value: "Iraq", label: "Iraq", code: "IRQ", short: "iq" },
  { value: "Israel", label: "Israel", code: "ISR", short: "il" },
  {
    value: "Virgin Islands, U.S.",
    label: "Virgin Islands, U.S.",
    code: "ISV",
    short: "vi",
  },
  { value: "Italy", label: "Italy", code: "ITA", short: "it" },
  {
    value: "Virgin Islands, British",
    label: "Virgin Islands, British",
    code: "IVB",
    short: "vg",
  },
  { value: "Jamaica", label: "Jamaica", code: "JAM", short: "jm" },
  { value: "Jordan", label: "Jordan", code: "JOR", short: "jo" },
  { value: "Japan", label: "Japan", code: "JPN", short: "jp" },
  { value: "Kazakhstan", label: "Kazakhstan", code: "KAZ", short: "kz" },
  { value: "Kenya", label: "Kenya", code: "KEN", short: "ke" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan", code: "KGZ", short: "kg" },
  { value: "Kiribati", label: "Kiribati", code: "KIR", short: "ki" },
  {
    value: "Korea, Republic of",
    label: "Korea, Republic of",
    code: "KOR",
    short: "kr",
  },
  { value: "Kosovo", label: "Kosovo", code: "KOS", short: "xk" },
  { value: "Saudi Arabia", label: "Saudi Arabia", code: "KSA", short: "sa" },
  { value: "Laos", label: "Laos", code: "LAO", short: "la" },
  { value: "Latvia", label: "Latvia", code: "LAT", short: "lv" },
  { value: "Libya", label: "Libya", code: "LBA", short: "ly" },
  { value: "Lebanon", label: "Lebanon", code: "LBN", short: "lb" },
  { value: "Saint Lucia", label: "Saint Lucia", code: "LCA", short: "lc" },
  { value: "Lesotho", label: "Lesotho", code: "LES", short: "ls" },
  { value: "Lithuania", label: "Lithuania", code: "LTU", short: "lt" },
  { value: "Luxembourg", label: "Luxembourg", code: "LUX", short: "lu" },
  { value: "Madagascar", label: "Madagascar", code: "MAD", short: "mg" },
  { value: "Morocco", label: "Morocco", code: "MAR", short: "ma" },
  { value: "Malaysia", label: "Malaysia", code: "MAS", short: "my" },
  { value: "Malawi", label: "Malawi", code: "MAW", short: "mw" },
  { value: "Moldova", label: "Moldova", code: "MDA", short: "md" },
  { value: "Maldives", label: "Maldives", code: "MDV", short: "mv" },
  { value: "Mexico", label: "Mexico", code: "MEX", short: "mx" },
  { value: "Mongolia", label: "Mongolia", code: "MGL", short: "mn" },
  {
    value: "Marshall Islands",
    label: "Marshall Islands",
    code: "MHL",
    short: "mh",
  },
  {
    value: "North Macedonia",
    label: "North Macedonia",
    code: "MKD",
    short: "mk",
  },
  { value: "Mali", label: "Mali", code: "MLI", short: "ml" },
  { value: "Malta", label: "Malta", code: "MLT", short: "mt" },
  { value: "Montenegro", label: "Montenegro", code: "MNE", short: "me" },
  { value: "Mozambique", label: "Mozambique", code: "MOZ", short: "mz" },
  { value: "Mauritius", label: "Mauritius", code: "MRI", short: "mu" },
  { value: "Mauritania", label: "Mauritania", code: "MTN", short: "mr" },
  { value: "Myanmar", label: "Myanmar", code: "MYA", short: "mm" },
  { value: "Namibia", label: "Namibia", code: "NAM", short: "na" },
  { value: "Nicaragua", label: "Nicaragua", code: "NCA", short: "ni" },
  { value: "Netherlands", label: "Netherlands", code: "NED", short: "nl" },
  { value: "Nepal", label: "Nepal", code: "NEP", short: "np" },
  { value: "Nigeria", label: "Nigeria", code: "NGR", short: "ng" },
  { value: "Niger", label: "Niger", code: "NIG", short: "ne" },
  { value: "Norway", label: "Norway", code: "NOR", short: "no" },
  { value: "New Zealand", label: "New Zealand", code: "NZL", short: "nz" },
  { value: "Oman", label: "Oman", code: "OMA", short: "om" },
  { value: "Pakistan", label: "Pakistan", code: "PAK", short: "pk" },
  { value: "Panama", label: "Panama", code: "PAN", short: "pa" },
  { value: "Paraguay", label: "Paraguay", code: "PAR", short: "py" },
  { value: "Peru", label: "Peru", code: "PER", short: "pe" },
  { value: "Philippines", label: "Philippines", code: "PHI", short: "ph" },
  { value: "Palestine", label: "Palestine", code: "PLE", short: "ps" },
  {
    value: "Papua New Guinea",
    label: "Papua New Guinea",
    code: "PNG",
    short: "pg",
  },
  { value: "Poland", label: "Poland", code: "POL", short: "pl" },
  { value: "Portugal", label: "Portugal", code: "POR", short: "pt" },
  { value: "Puerto Rico", label: "Puerto Rico", code: "PUR", short: "pr" },
  { value: "Qatar", label: "Qatar", code: "QAT", short: "qa" },
  { value: "Romania", label: "Romania", code: "ROU", short: "ro" },
  { value: "South Africa", label: "South Africa", code: "RSA", short: "za" },
  { value: "Rwanda", label: "Rwanda", code: "RWA", short: "rw" },
  { value: "Senegal", label: "Senegal", code: "SEN", short: "sn" },
  { value: "Seychelles", label: "Seychelles", code: "SEY", short: "sc" },
  { value: "Singapore", label: "Singapore", code: "SGP", short: "sg" },
  {
    value: "Saint Kitts and Nevis",
    label: "Saint Kitts and Nevis",
    code: "SKN",
    short: "kn",
  },
  { value: "Sierra Leone", label: "Sierra Leone", code: "SLE", short: "sl" },
  { value: "Slovenia", label: "Slovenia", code: "SLO", short: "si" },
  { value: "San Marino", label: "San Marino", code: "SMR", short: "sm" },
  {
    value: "Solomon Islands",
    label: "Solomon Islands",
    code: "SOL",
    short: "sb",
  },
  { value: "Serbia", label: "Serbia", code: "SRB", short: "rs" },
  { value: "Sri Lanka", label: "Sri Lanka", code: "SRI", short: "lk" },
  {
    value: "Sao Tome and Principe",
    label: "Sao Tome and Principe",
    code: "STP",
    short: "st",
  },
  { value: "Sudan", label: "Sudan", code: "SUD", short: "sd" },
  { value: "Switzerland", label: "Switzerland", code: "SUI", short: "ch" },
  { value: "Slovakia", label: "Slovakia", code: "SVK", short: "sk" },
  { value: "Sweden", label: "Sweden", code: "SWE", short: "se" },
  { value: "Eswatini", label: "Eswatini", code: "SWZ", short: "sz" },
  { value: "Syria", label: "Syria", code: "SYR", short: "sy" },
  { value: "Tanzania", label: "Tanzania", code: "TAN", short: "tz" },
  { value: "Tonga", label: "Tonga", code: "TGA", short: "to" },
  { value: "Thailand", label: "Thailand", code: "THA", short: "th" },
  { value: "Tajikistan", label: "Tajikistan", code: "TJK", short: "tj" },
  { value: "Turkmenistan", label: "Turkmenistan", code: "TKM", short: "tm" },
  { value: "Timor-Leste", label: "Timor-Leste", code: "TLS", short: "tl" },
  { value: "Togo", label: "Togo", code: "TOG", short: "tg" },
  {
    value: "Chinese Taipei",
    label: "Chinese Taipei",
    code: "TPE",
    short: "15",
  },
  {
    value: "Trinidad and Tobago",
    label: "Trinidad and Tobago",
    code: "TTO",
    short: "tt",
  },
  { value: "Tunisia", label: "Tunisia", code: "TUN", short: "tn" },
  { value: "Turkey", label: "Turkey", code: "TUR", short: "tr" },
  {
    value: "United Arab Emirates",
    label: "United Arab Emirates",
    code: "UAE",
    short: "ae",
  },
  { value: "Uganda", label: "Uganda", code: "UGA", short: "ug" },
  { value: "Ukraine", label: "Ukraine", code: "UKR", short: "ua" },
  { value: "Uruguay", label: "Uruguay", code: "URU", short: "uy" },
  {
    value: "United States of America",
    label: "United States of America",
    code: "USA",
    short: "us",
  },
  { value: "Uzbekistan", label: "Uzbekistan", code: "UZB", short: "uz" },
  { value: "Vanuatu", label: "Vanuatu", code: "VAN", short: "vu" },
  { value: "Venezuela", label: "Venezuela", code: "VEN", short: "ve" },
  { value: "Vietnam", label: "Vietnam", code: "VIE", short: "vn" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
    code: "VIN",
    short: "vc",
  },
  { value: "Zambia", label: "Zambia", code: "ZAM", short: "zm" },
  { value: "Zimbabwe", label: "Zimbabwe", code: "ZIM", short: "zw" },
];

export default Countries;
