import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useMediaQuery } from "@mui/material";
import moment from "moment";

import { Tables } from "../Components/database.types";
import AppPage from "../Components/AppPage";
import Countries from "../Components/countries";

const tmp: Tables<"logs"> = {
  assigned: "",
  country: "",
  helpType: "",
  id: 0,
  isComplete: false,
  isNew: false,
  location: "",
  notes: "",
  startTime: 0,
  stopTime: 0,
};

const typeText = (type: string) => {
  if (type == "hardware") {
    return "Hardware";
  }

  if (type == "software") {
    return "Software";
  }

  if (type == "software&hardware") {
    return "Hardware and Software";
  }
};

export default function InspectionList() {
  const [tableData, setTableData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetch(
      "https://fgc-inspection-site.azurewebsites.net/inspection/api/robot-hospital/"
    )
      .then((response) => response.json())
      .then((data) => {
        if (data != null) {
          setTableData(data);
          setLoading(false);
        }
      })
      .catch((e) => {
        setTableData([]);
        setLoading(false);
      });
  }, []);

  const isBig = useMediaQuery("(min-width:800px)");

  const columns: GridColDef[] = [
    {
      field: "country",
      headerName: "Country",
      flex: 1.5,
      valueFormatter: (params) => {
        let country = Countries.filter((c) => c.code == params.value).pop();

        return country ? country.label : "No match";
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.75,
      valueFormatter: (params) => typeText(params.value),
    },
    { field: "reported_by", headerName: "Reporter", flex: 1.25 },
    {
      field: "timestamp",
      headerName: "Time",
      flex: 1,
      valueFormatter: (params) => moment(params?.value).format("hh:mmA DD-MMM"),
    },
  ];

  return (
    <AppPage title="Inspection Issues">
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={tableData}
          columns={columns}
          loading={isLoading}
          autoHeight
          disableRowSelectionOnClick
          initialState={{
            columns: {
              columnVisibilityModel: {
                country: true,
                type: true,
                reported_by: isBig,
                timestamp: true,
              },
            },
            sorting: {
              sortModel: [{ field: "timestamp", sort: "asc" }],
            },
            filter: {
              filterModel: {
                items: [
                  {
                    field: "isComplete",
                    operator: "contains",
                    value: "false",
                  },
                ],
              },
            },
          }}
        />
      </div>
    </AppPage>
  );
}
