import * as React from "react";
import { Alert, List, ListItem, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import AppPage from "../Components/AppPage";

const img_style = {
  maxWidth: "100%",
  minWidth: 100,
  height: "auto",
  padding: 0,
  margin: 0,
};

export default function ServoGears() {
  const gears_rows = [
    {
      img: <img src="step1.png" style={img_style}></img>,
      text: "Remove the four screws that hold the upper casing.",
    },
    {
      img: <img src="step2.png" style={img_style}></img>,
      text: "Remove the upper casing. Orient the servo so that the servo wire is to the left-hand side.",
    },
    {
      img: <img src="step3.png" style={img_style}></img>,
      text: "Remove the the reduction and output gears. The last silver gear is removed with the axle as one unit.",
    },
    {
      img: <img src="step4.png" style={img_style}></img>,
      text: "Remove the axle pin from the silver gear and replace gear if necessary. Note: replacement gears are shipped un-greased. Please grease all replacement gears before installing.",
    },
    {
      img: <img src="step5.png" style={img_style}></img>,
      text: "Attach silver gear to the axle pin.",
    },
    {
      img: <img src="step6.png" style={img_style}></img>,
      text: "Place the new silver gear onto the servo unit making sure contact with the servo  motor's output shaft first, then slide the axle into the axle opening.",
    },
    {
      img: <img src="step7.png" style={img_style}></img>,
      text: "Place the second gear in place. Make sure the lower level of teeth on this gear mesh with the upper level of teeth on the silver gear.",
    },
    {
      img: <img src="step8.png" style={img_style}></img>,
      text: "Next place the output shaft gear in position. Make sure to align the slot in the gear with the tab on the servo. ",
    },
    {
      img: <img src="step9.png" style={img_style}></img>,
      text: "Once the output shaft is in place correctly, it should be seated securely but not touching any other gears. ",
    },
    {
      img: <img src="step10.png" style={img_style}></img>,
      text: "Place the final gear on the middle shaft. It should first mesh with the output shaft on its lower set of teeth, and then its upper teeth will mesh with the gear on the right shaft.",
    },
    {
      img: <img src="step11.png" style={img_style}></img>,
      text: "Finally, reattach the top plastic cover, making sure to place it directly down on top of the gears so that they don’t become misaligned. Reattach the 4 screws and the servo is ready to operate.",
    },
  ];
  return (
    <AppPage title="Servo Gear Swap">
      <Typography component="h5" variant="h5" sx={{ px: 0 }} color="inherit">
        Tools Needed
      </Typography>
      <Typography component="p" sx={{ px: 0 }} color="inherit">
        To replace the gear set in the Smart Robot Servo you will need the
        following items:
      </Typography>
      <List
        sx={{
          listStyleType: "disc",
          pl: 2,
          ml: 5,
          "& .MuiListItem-root": {
            display: "list-item",
          },
        }}
      >
        <ListItem sx={{ py: 0, my: 0 }}>
          Smart Robot Servo (REV-41-1097) - Quantity 1
        </ListItem>
        <ListItem sx={{ py: 0, my: 0 }}>
          Replacement Gear Set (REV-41-1168) - Quantity 1
        </ListItem>
        <ListItem sx={{ py: 0, my: 0 }}>
          Phillips Head Screwdriver (PH0) - Quantity 1
        </ListItem>
      </List>
      <Alert severity="warning" sx={{ my: 3 }}>
        The replacement gears are shipped un-greased. They need grease to run
        smoothly, so make sure the is enough grease in the gearbox, and if not,
        add equivalent of ~1/4” diameter sphere of grease to gears in the
        gearbox.
      </Alert>
      <Typography component="h5" variant="h5" sx={{ px: 0 }} color="inherit">
        Replacing Gears Walkthrough
      </Typography>
      <Table sx={{ alignSelf: "center" }} size="small">
        <colgroup>
          <col style={{ width: "30%" }} />
          <col style={{ width: "70%" }} />
        </colgroup>
        <TableBody>
          {gears_rows.map((row) => (
            <TableRow
              key={row.text}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ minWidth: 220, maxWidth: 400, p: 0 }}
              >
                {row.img}
              </TableCell>
              <TableCell sx={{ maxWidth: 500 }}>{row.text}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </AppPage>
  );
}
