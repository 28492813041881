import * as React from "react";

import AppPage from "../Components/AppPage";
import Countries from "../Components/countries";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Autocomplete, TextField, useMediaQuery } from "@mui/material";
import moment from "moment";
import "../Components/colStyles.css";

const fetchCounty = (code: string) => {
  return Countries.filter((c) => c.code == code).pop();
};

const fetchFlag = (code: string) => {
  return (
    <img
      src={`https://results.first.global/_next/image?url=/static/flags/4x3/${code}.svg&w=16&q=10`}
      width={20}
      height={15}
      style={{
        backgroundColor: "#ffffff",
        marginRight: "0.1em",
      }}
    ></img>
  );
};

interface Country {
  value: string;
  label: string;
  code: string;
  short: string;
}

interface MatchData {
  id: number;
  label: number;
  red1: Country;
  red2: Country;
  red3: Country;
  blue1: Country;
  blue2: Country;
  blue3: Country;
  scheduledTime: string;
  field: number;
}

export default function MatchViewer() {
  const [matchData, setData] = React.useState<MatchData[]>([]);
  const [isLoading, setLoading] = React.useState(true);
  const [filteredData, setFiltered] = React.useState<MatchData[]>([]);

  const isBig = useMediaQuery("(min-width:900px)");

  const renderFlag = (params: GridRenderCellParams<Country>) => {
    return (
      <div>
        {fetchFlag(params.value?.short.toLowerCase())}
        {isBig ? "" : <br />}
        {params.value?.code}
      </div>
    );
  };

  const countryMatches = (code: string) => {
    return matchData.filter((m) => {
      return (
        m.red1.code == code ||
        m.red2.code == code ||
        m.red3.code == code ||
        m.blue1.code == code ||
        m.blue2.code == code ||
        m.blue3.code == code
      );
    });
  };

  const handleCountry = (
    event: React.SyntheticEvent<Element, Event>,
    newCon: {
      value: string;
      label: string;
      code: string;
    } | null
  ) => {
    if (newCon !== null) {
      setFiltered(countryMatches(newCon.code));
      console.log(newCon);
    } else {
      setFiltered(matchData);
    }
  };

  React.useEffect(() => {
    const getData = () =>
      fetch("https://api.first.global/v1")
        .then((response) => response.json())
        .then((data) => {
          if (data != null) {
            const filtered = data.matches.filter((m: any) => !m.played);
            const newSet = filtered.map((match: any) => {
              return {
                id: match.id,
                label: match.id,
                red1: fetchCounty(match.participants[0].country),
                red2: fetchCounty(match.participants[1].country),
                red3: fetchCounty(match.participants[2].country),
                blue1: fetchCounty(match.participants[3].country),
                blue2: fetchCounty(match.participants[4].country),
                blue3: fetchCounty(match.participants[5].country),
                scheduledTime: moment(match.scheduledTime).format("H:mm ddd"),
                field: match.field,
              };
            });
            setData(newSet);
            setLoading(false);
          }
        })
        .catch((e) => {
          setData([]);
        });

    getData().then(() => setFiltered(matchData));
    const inter = setInterval(getData, 6000);
    return () => clearInterval(inter);
  }, []);

  const columns: GridColDef[] = [
    {
      field: "label",
      headerName: "Match",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "field",
      headerName: "Field",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "red1",
      headerName: "",
      flex: 0.5,
      renderCell: renderFlag,
      align: "center",
      headerAlign: "center",
      headerClassName: "redAlliance",
      cellClassName: "redAlliance",
      sortable: false,
    },
    {
      field: "red2",
      headerName: "Red",
      flex: 0.5,
      renderCell: renderFlag,
      align: "center",
      headerAlign: "center",
      headerClassName: "redAlliance",
      cellClassName: "redAlliance",
      sortable: false,
    },
    {
      field: "red3",
      headerName: "",
      flex: 0.5,
      renderCell: renderFlag,
      align: "center",
      headerAlign: "center",
      headerClassName: "redAlliance",
      cellClassName: "redAlliance",
      sortable: false,
    },
    {
      field: "blue1",
      headerName: "",
      flex: 0.5,
      renderCell: renderFlag,
      align: "center",
      headerAlign: "center",
      headerClassName: "blueAlliance",
      cellClassName: "blueAlliance",
      sortable: false,
    },
    {
      field: "blue2",
      headerName: "Blue",
      flex: 0.5,
      renderCell: renderFlag,
      align: "center",
      headerAlign: "center",
      headerClassName: "blueAlliance",
      cellClassName: "blueAlliance",
      sortable: false,
    },
    {
      field: "blue3",
      headerName: "",
      flex: 0.5,
      renderCell: renderFlag,
      align: "center",
      headerAlign: "center",
      headerClassName: "blueAlliance",
      cellClassName: "blueAlliance",
      sortable: false,
    },
    {
      field: "scheduledTime",
      headerName: "Scheduled",
      flex: 1.5,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
  ];

  return (
    <AppPage title="Upcoming Matches">
      <Autocomplete
        disablePortal
        options={Countries}
        onChange={(e, v) => handleCountry(e, v)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Country"
            sx={{ pb: 2 }}
          />
        )}
      />
      <div>
        <DataGrid
          rows={filteredData.length > 0 ? filteredData : matchData}
          columns={columns}
          loading={isLoading}
          autoHeight
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                label: isBig,
                red1: true,
                red2: true,
                red3: true,
                blue1: true,
                blue2: true,
                blue3: true,
                scheduledTime: true,
                field: true,
              },
            },
          }}
        />
      </div>
    </AppPage>
  );
}
