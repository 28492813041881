import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Alert, Button, Snackbar, SnackbarCloseReason } from "@mui/material";
import Countries from "../Components/countries";
import AppPage from "../Components/AppPage";
import { Observable } from "@legendapp/state";
import { Log } from "../Components/database.types";
import { supabase } from "../Components/DbConnection";

export default function HelpForm() {
  const [showSnack, setSnack] = React.useState(false);
  const [conKey, setConKey] = React.useState(0);
  const [notesKey, setNotesKey] = React.useState(-1);
  const [assignedKey, setAssignedKey] = React.useState(10);
  const [country, setCountry] = React.useState("");
  const [helpType, setHelpType] = React.useState("Machine Shop");
  const [isNew, setIsNew] = React.useState(true);
  const [helpLoc, setLoc] = React.useState("Machine Shop");
  const [assigned, setAssigned] = React.useState("");
  const [notes, setNotes] = React.useState("");

  const handleSnack = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnack(false);
  };

  async function submitForm() {
    const startTime = Date.now();
    const newLog = {
      id: startTime,
      country: country,
      helpType: helpType,
      isNew: isNew,
      location: helpLoc,
      notes: notes,
      startTime: startTime,
      stopTime: 0,
      assigned: assigned,
      isComplete: false,
    };

    setConKey(conKey + 1);
    setAssignedKey(assignedKey + 1);
    setNotesKey(notesKey - 1);
    setHelpType("Machine Shop");
    setIsNew(false);
    setLoc("Machine Shop");
    setCountry("");
    setNotes("");
    setAssigned("");

    await supabase.from("logs").insert(newLog);

    setSnack(true);
  }

  const handleType = (
    event: React.MouseEvent<HTMLElement>,
    newType: string | null
  ) => {
    if (newType !== null) {
      setHelpType(newType);

      if (newType === "Charging") {
        setNotes("Robot Battery");
      }
    }
  };

  const handleLoc = (
    event: React.MouseEvent<HTMLElement>,
    newLoc: string | null
  ) => {
    if (newLoc !== null) {
      setLoc(newLoc);
    }
  };

  const handleCountry = (
    event: React.SyntheticEvent<Element, Event>,
    newCon: {
      value: string;
      label: string;
    } | null
  ) => {
    if (newCon !== null) {
      setCountry(newCon.value);
      return;
    }
  };

  return (
    <AppPage title="Intake Form">
      <Stack spacing={4}>
        <Autocomplete
          disablePortal
          options={Countries}
          key={conKey}
          onChange={(e, v) => handleCountry(e, v)}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Country" />
          )}
        />

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 3 }}
        >
          <Typography
            component="h3"
            variant="h6"
            sx={{ pt: 1 }}
            color="inherit"
          >
            Help Type:
          </Typography>
          <ToggleButtonGroup
            color="primary"
            value={helpType}
            exclusive
            onChange={handleType}
            aria-label="text alignment"
          >
            <ToggleButton value="Hardware" aria-label="Hardware">
              Hardware
            </ToggleButton>
            <ToggleButton value="Software" aria-label="Software">
              Software
            </ToggleButton>
            <ToggleButton value="Machine Shop" aria-label="Machine Shop">
              Machine Shop
            </ToggleButton>
            <ToggleButton value="Charging" aria-label="Charging">
              Charging
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        {helpType != "Charging" && (
          <>
            <Stack direction="row" spacing={3}>
              <Typography
                component="h3"
                variant="h6"
                sx={{ pt: 1 }}
                color="inherit"
              >
                Is this a new problem?
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={isNew ? "new" : "old"}
                exclusive
                onChange={(e, v) => setIsNew(v === "new")}
                aria-label="Platform"
              >
                <ToggleButton value="new">New</ToggleButton>
                <ToggleButton value="old">Old</ToggleButton>
              </ToggleButtonGroup>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 3 }}
            >
              <Typography
                component="h3"
                variant="h6"
                sx={{ pt: 1 }}
                color="inherit"
              >
                Help Location:
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={helpLoc}
                exclusive
                onChange={handleLoc}
                aria-label="text alignment"
              >
                <ToggleButton value="Hospital" aria-label="Hospital">
                  Hospital
                </ToggleButton>
                <ToggleButton value="Team Pit" aria-label="Team Pit">
                  Team Pit
                </ToggleButton>
                <ToggleButton value="Machine Shop" aria-label="Machine Shop">
                  Machine Shop
                </ToggleButton>
                <ToggleButton value="Queue" aria-label="Queue">
                  Queue
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <TextField
              label="Problem details"
              variant="standard"
              onChange={(v) => setNotes(v.target.value)}
              key={notesKey}
            />
            <TextField
              label="Person helping"
              variant="standard"
              onChange={(v) => setAssigned(v.target.value)}
              key={assignedKey}
              sx={{ pb: 3 }}
            />
          </>
        )}
        {helpType === "Charging" && (
          <Stack direction="row" spacing={3}>
            <Typography
              component="h3"
              variant="h6"
              sx={{ pt: 1 }}
              color="inherit"
            >
              Charging Type:
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={notes === "Robot Battery" ? "Robot Battery" : "Driver Hub"}
              exclusive
              onChange={(e, v) => setNotes(v)}
              aria-label="Platform"
            >
              <ToggleButton value="Robot Battery">Robot Battery</ToggleButton>
              <ToggleButton value="Driver Hub">Driver Hub</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        )}
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={country.length === 0}
          onClick={submitForm}
        >
          Submit
        </Button>
      </Stack>
      <Snackbar
        open={showSnack}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={handleSnack}
      >
        <Alert
          onClose={handleSnack}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Submitted
        </Alert>
      </Snackbar>
    </AppPage>
  );
}
