import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { Button, TextField, useMediaQuery } from "@mui/material";
import moment from "moment";

import { Log, Tables } from "../Components/database.types";
import Countries from "../Components/countries";
import InfoCard from "../Components/InfoCard";
import AppPage from "../Components/AppPage";
import { HelpTableProps } from "./CurrentTable";

const tmp: Tables<"logs"> = {
  assigned: "",
  country: "",
  helpType: "",
  id: 0,
  isComplete: false,
  isNew: false,
  location: "",
  notes: "",
  startTime: 0,
  stopTime: 0,
};

export default function LookupPage(props: HelpTableProps) {
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState<Tables<"logs">>(tmp);
  const [country, setCountry] = React.useState(".");
  const [code, setCode] = React.useState("");

  const notSmall = useMediaQuery("(min-width:800px)");
  const isBig = useMediaQuery("(min-width:1200px)");

  const handleClickOpen = (row: Tables<"logs">) => {
    setRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCountry = (
    event: React.SyntheticEvent<Element, Event>,
    newCon: {
      value: string;
      label: string;
      code: string;
    } | null
  ) => {
    if (newCon !== null) {
      setCountry(newCon.value);
      setCode(newCon.code);
      return;
    } else {
      setCountry(".");
      setCode("");
      return;
    }
  };

  const columns: GridColDef[] = [
    { field: "country", headerName: "Country", flex: 1.75 },
    { field: "helpType", headerName: "Type", flex: 1 },
    {
      field: "isNew",
      headerName: "New?",
      flex: 0.75,
      renderCell: (params: GridRenderCellParams<Boolean>) => (
        <div>{params.value ? "Yes" : "No"}</div>
      ),
    },
    { field: "location", headerName: "Location", flex: 1 },
    {
      field: "assigned",
      headerName: "Assigned",
      flex: 0.875,
    },
    { field: "notes", headerName: "Notes", flex: 3 },
    {
      field: "startTime",
      headerName: "Start Time",
      flex: 1.5,
      valueFormatter: (params) => moment(params?.value).format("hh:mmA DD-MMM"),
    },
    {
      field: "stopTime",
      headerName: "Stop Time",
      flex: 1.5,
      valueFormatter: (params) => moment(params?.value).format("hh:mmA DD-MMM"),
    },
    {
      field: "isComplete",
      headerName: "Complete?",
      flex: 1.25,
      renderCell: (params: GridRenderCellParams<Boolean>) => (
        <div>{params.value ? "Yes" : "No"}</div>
      ),
    },
    {
      field: "info",
      headerName: "Info",
      flex: 0.75,
      renderCell: (params: GridRenderCellParams) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            tabIndex={params.hasFocus ? 0 : -1}
            onClick={() => handleClickOpen(params.row)}
          >
            Open
          </Button>
        </strong>
      ),
    },
  ];

  return (
    <AppPage title="Country Lookup">
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={Countries}
        onChange={(e, v) => handleCountry(e, v)}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Country" />
        )}
      />
      <Typography
        component="h5"
        variant="h6"
        sx={{ px: 0, pb: 3, pt5: 3, mt: 3, fontWeight: 500 }}
        color="inherit"
      >
        Country Code: {code}
      </Typography>
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={props.tableData}
          columns={columns}
          autoHeight
          disableRowSelectionOnClick
          initialState={{
            columns: {
              columnVisibilityModel: {
                stopTime: false,
                helpType: notSmall,
                isNew: isBig,
                startTime: notSmall,
                location: isBig,
                notes: isBig,
                isComplete: true,
                info: true,
                country: false,
              },
            },
            sorting: {
              sortModel: [{ field: "startTime", sort: "asc" }],
            },
            filter: {
              filterModel: {
                items: [
                  {
                    field: "country",
                    operator: "equals",
                    value: country,
                  },
                ],
              },
            },
          }}
          filterModel={{
            items: [{ field: "country", operator: "equals", value: country }],
          }}
        />
      </div>
      <InfoCard row={row} open={open} onClose={handleClose} />
    </AppPage>
  );
}
