import { Container, Paper, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { PropsWithChildren } from "react";

type PageProps = {
  title: string;
};

export default function AppPage(props: PropsWithChildren<PageProps>) {
  const isBig = useMediaQuery("(min-width:900px)");
  return (
    <Container
      disableGutters={!isBig}
      maxWidth={false}
      sx={{
        ml: { xs: 0, xl: "240px" },
        mr: 0,
        mt: 2,
        mb: 1,
        p: 0,
        width: { xs: "100%", xl: "calc(100% - 240px)" },
        backgroundColor: (theme) => theme.palette.grey[100],
      }}
    >
      <Paper
        sx={{
          p: { xs: 0, md: 2 },
          m: 0,
        }}
      >
        <Typography
          component="h3"
          variant="h4"
          sx={{ px: 0, pb: 3, fontWeight: 700 }}
          color="inherit"
        >
          {props.title}
        </Typography>
        {props.children}
      </Paper>
    </Container>
  );
}
