import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { ReactElement } from "react";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import MenuIcon from "@mui/icons-material/Menu";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { Link, useLocation } from "react-router-dom";

export interface TopBarProps {
  toggleDrawer: Function;
  open: boolean;
}

type sidebarPage = {
  to: string;
  icon: ReactElement;
  label: string;
};

const pages: sidebarPage[] = [
  { to: "/", icon: <NoteAltIcon />, label: "Intake Form" },
  { to: "/current", icon: <ChecklistIcon />, label: "Help Requests" },
  {
    to: "/charging",
    icon: <BatteryChargingFullIcon />,
    label: "Battery Charging",
  },
  { to: "/inspect", icon: <ReportProblemIcon />, label: "Inspection Issues" },
  { to: "/lookup", icon: <ManageSearchIcon />, label: "Country Lookup" },
  {
    to: "/servo",
    icon: <SettingsApplicationsIcon />,
    label: "Servo Programming",
  },
  { to: "/gears", icon: <BuildCircleIcon />, label: "Servo Gear Swap" },
  { to: "/stats", icon: <AnalyticsIcon />, label: "Flying Squad Stats" },
  { to: "/schedule", icon: <PendingActionsIcon />, label: "Match Schedule" },
];

const externalPages: sidebarPage[] = [
  {
    to: "https://docs.google.com/document/d/1YHySTy_rjoouijgbzW_Q6a6ULCPMzZs0Gyg3Dnhmpo4/view",
    icon: <OpenInNewIcon />,
    label: "Game Manual",
  },
  {
    to: "http://fgc-inspection-site.azurewebsites.net/inspection/",
    icon: <OpenInNewIcon />,
    label: "Inspection App",
  },
];

export default function TopBar(props: TopBarProps) {
  const drawerWidth: number = 240;
  const { pathname } = useLocation();

  const drawer = (
    <List component="nav">
      <Toolbar />
      {pages.map((page, idx) => (
        <ListItemButton
          component={Link}
          to={page.to}
          selected={pathname === page.to}
          key={idx}
          onClick={props.toggleDrawer(false)}
        >
          <ListItemIcon>{page.icon}</ListItemIcon>
          <ListItemText primary={page.label} />
        </ListItemButton>
      ))}
      <Divider sx={{ mt: 1, mb: 2 }} />
      {externalPages.map((page) => (
        <ListItemButton
          to={page.to}
          component={Link}
          target="_blank"
          onClick={props.toggleDrawer(false)}
          sx={{
            borderColor: "#ccc",
            borderWidth: 1,
            borderRadius: 2,
            borderStyle: "solid",
            m: 1,
          }}
          key={page.label}
        >
          <ListItemIcon>
            <OpenInNewIcon />
          </ListItemIcon>
          <ListItemText primary={page.label} />
        </ListItemButton>
      ))}
    </List>
  );

  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        style={{ margin: 0, padding: 0 }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar style={{ paddingRight: 4 }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.toggleDrawer(true)}
            sx={{
              display: { lg: "block", xl: "none" },
              marginRight: "36px",
              ...(props.open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h4"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          ></Typography>
          <Link to="/" onClick={props.toggleDrawer(false)}>
            <img src="./logo.svg" />
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={props.open}
        onClose={props.toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", xl: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", xl: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
}
